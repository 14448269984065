<template>
    <a-card>
        <a-page-header
            title="赠送视频"
        />
        <div class="content">
            <div class="row"><span class="title">视频品牌：</span><span class="txt">{{ dataForm.principalName || '-' }}</span></div>
            <div class="row"><span class="title">视频车系：</span><span class="txt">{{ dataForm.carSeriesName || '-' }}</span></div>
            <div class="row"><span class="title">集客场景：</span><span class="txt">{{ dataForm.customerSceneDesc || '-' }}</span></div>
            <div class="row"><span class="title">广告平台：</span><span class="txt">{{ dataForm.adPlatform || '-' }}</span></div>
        </div>
        <div class="info">已匹配相同品牌且有托管相同广告平台的广告主<span>{{ pagination.total }}</span>个，当前已选中<span>{{ selectedRowKeys.length }}</span>个</div>

<div class="btn-wrap">
    <a-space>
        <base-button
            :title="'取消'"
            @onClickBtn="handleBack"
        ></base-button>
        <base-button
            :type="'primary'"
            :title="'提交'"
            :loading="isSubmit"
            :disabled="isSubmit"
            @onClickBtn="handleSubmit"
        ></base-button>
    </a-space>
</div>

        <a-table
            :loading="loading"
            :columns="columns"
            :data-source="dataList"
            :rowKey="record => record.advertiserId"
            :row-selection="rowSelection"
            :pagination='false'
        >
            <div
                slot="operation"
                slot-scope="text, record"
            >
                <a>更多信息</a>
                <a-divider type="vertical" />

                <a >赠送</a>
            </div>
        </a-table>

        <base-pagination
            :currentPage="pagination.current"
            :pageSize="pagination.pageSize"
            :total="pagination.total"
            @onChange="handlePaginationChange"
            @onShowSizeChange="handlePaginationChange"
        />
    </a-card>
</template>

<script>
export default {
    data() {
        return {
            id: this.$route.query.id,
            dataForm: {},
            loading: false,
            columns: [
                {
                    align: 'center',
                    title: '广告主编码',
                    dataIndex: 'advertiserCode',
                    customRender(text) {
                        return text || '-'
                    }
                },
                {
                    align: 'center',
                    title: '广告主',
                    dataIndex: 'advertiserName',
                    customRender(text) {
                        return text || '-'
                    }
                },
                {
                    align: 'center',
                    title: '品牌（主体）',
                    dataIndex: 'principalName',
                    customRender(text) {
                        return text || '-'
                    }
                },
                {
                    align: 'center',
                    title: '状态',
                    // dataIndex: 'status',
                    customRender: (text, row) => {
                        let txt = <div class="red">关闭</div>
                        return row.status == 1 ? '开启' :  txt
                    }
                },
                {
                    align: 'center',
                    title: '广告平台',
                    dataIndex: 'adPlatform',
                    customRender(text) {
                        return text || '-'
                    }
                },
            ],
            dataList: [],
            pagination: {
                current: 1,
                pageSize: 100,
                total: 0
            },
            selectedRowKeys: [],
            isSubmit: false,
            isFirstRequest: true
        }
    },
    computed: {
        rowSelection() {
            const { selectedRowKeys } = this;
            return {
                selectedRowKeys,
                onChange: (selectedRowKeys) => {
                    this.selectedRowKeys = selectedRowKeys
                },
            };
        },
    },
    created() {
        this.getGiftDeliveryVideoDetail()
        this.getGiftDeliveryVideoList()
    },
    methods: {
        getGiftDeliveryVideoDetail() {
            this.$api.core.materialManage.getGiftDeliveryVideoDetail({ hjkAdVideoId: this.id }).then(res => {
                if(res.code == 200) {
                    this.dataForm = res.data
                } else {
                    console.error(`获取详情失败，${res}`)
                }
            })
        },
        handlePaginationChange(current, pageSize) {
            this.pagination.current = current
            this.pagination.pageSize = pageSize
            this.getGiftDeliveryVideoList()
        },
        getGiftDeliveryVideoList() {
            this.loading = true

            let params = {
                hjkAdVideoId: this.id,
                page: this.pagination.current,
                size: this.pagination.pageSize
            }
            this.$api.core.materialManage.getGiftDeliveryVideoList(params).then(res => {
                this.loading = false
                if(res.code == 200) {
                    this.dataList = res.data.list || []
                    this.pagination.total = res.data.total || 0

                    if(this.isFirstRequest) {
                        this.dataList.map(item => {
                            this.selectedRowKeys.push(item.advertiserId)
                        })
                    }

                    this.isFirstRequest = false
                } else {
                    this.dataList = []
                    this.pagination.total = 0
                    console.error(`获取列表失败${res}`)
                }
            })
        },
        handleBack() {
            let that = this
            this.$confirm({
                content: h => <div><span style="color: red; display: block;">表单数据还未保存</span>离开数据将会丢失，是否确认离开？
                </div>,
                okText: '确定',
                cancelText: '取消',
                onOk () {
                    that.$router.push({
                        path: '/castVideoManage'
                    })
                }
            })
        },
        handleSubmit() {
            if(this.selectedRowKeys.length < 1) {
                this.$message.error('请选择广告主')
                return
            }
            let params = {
                advertiserIdList: this.selectedRowKeys,
                hjkAdVideoId: this.id
            }
            this.$api.core.materialManage.giftDeliveryVideoDetail( params ).then(res => {
                if(res.code == 200) {
                    this.$message.success('提交成功')
                    this.$router.push({
                        path: '/castVideoManage'
                    })
                } else {
                    this.$message.error(`提交失败，${res}`)
                }
            })
        }
    },
}
</script>

<style scoped lang="less">
/deep/ .ant-page-header {
    padding-left: 0;
    padding-right: 0;
}
.info {
    margin: 40px 10px 10px;
    span {
        padding: 0 2px;
        font-weight: bold;
        color: red;
    }
}
.row {
    display: flex;
    margin: 15px 20px;
    line-height: 1.5;
    .title {
        width: 80px;
        font-weight: bold;
        text-align: right;
    }
    .txt {
        flex: 1;
        margin-left: 10px;
        word-break: break-word;
    }
}
.btn-wrap {
    margin: 30px 20px;
}
</style>